/**
 * @Author Irfan Nasim
 * @since 24-Jun-2020
 * @Description Application URL Constants
 */
export class APIURLConstants {

    // Registration API URL
    public static REGISTER_API_URL = '/customer/register';
    public static SEND_VERIFICATION_OTP_API_URL = '/customer/sendVerificationOTP';
    public static UPDATE_USER_STATUS_API_URL = '/customer/verify-user';
    public static UPDATE_USER_PROFILE_API_URL = '/customer/update-profile';
    public static UPDATE_USER_NAME = '/customer/update-profile-name/';
    public static UPDATE_USER_PROFILE_IMAGE_API_URL = '/customer/updateProfilePic/';
    public static CHANGE_MOBILE_NO_API_URL = '/customer/changeMobileNo/';
    public static CHANGE_CUSTOMER_MOBILE_NO_API_URL = '/customer/changeCustomerMobileNo';
    public static RESEND_OTP_API_URL = '/customer/resendOTP/';
    public static RESEND_OTP_PHONE_NUMBER_API_URL = '/customer/resendOTPViaSMS?mobile_no=';
    public static GET_FAVOURITE_DOCTORS_BY_CUSTOMER_ID = '/customer/getFavouriteDoctorsById/';
    public static GET_FAVOURITE_OFFERS_BY_CUSTOMER_ID = '/customer/getFavouriteOffersById/';
    public static GET_FAVOURITE_BRANCH_BY_CUSTOMER_ID = '/customer/getFavouriteBranchesByCustomer/';
    public static FORGOT_PASSWORD_API_URL = '/core/customer/password/reset';
    public static SEND_OTP_NO_API_URL = '/customer/send-otp';
    public static CHANGE_MOBILE_PROFILE_NO_API_URL = '/customer/changeCustomerMobileNo';
    public static SEND_ACTIVATION_EMAIL_API_URL = '/customer/send-activation-email';
    public static ACTIVATE_CUSTOMER_EMAIL_API_URL = '/customer/activateCsEmail';

    // Authentication API URL
    public static LOGIN_API_URL = '/core/login';
    public static LOGOUT_API_URL = '/core/logout';
    public static LOGGED_IN_USER_URL = '/core/loggedInUser';
    public static CHANGE_PASSWORD_API_URL = '/user/change-password';

    // Locale API URL
    public static COUNTRIES_API_URL = '/core/get-countries';
    public static CITES_WITH_ID_API_URL = '/core/get-city-with-id';
    public static GET_ALL_CITIES_API_URL = '/core/get-cities';
    public static saveCustomerFavourite = '/customer/saveCustomerFavourite';
    public static removeCustomerFavourite = '/customer/removeCustomerFavourite/';
    public static GET_BRANCH_COUNTRIES_API_URL = '/content/branches-countries';


    // Customer Member
    public static CUSTOMER_MEMBER_API_URL = '/customer/member';
    public static CUSTOMER_MEMBER_API_URL_NEW = '/customer/get-members-list';
    public static CONTACT_INFO_API_URL = '/contact/getContactInfo';
    public static CONTACT_US_API_URL = '/contact/sendMail';
    public static CONTACT_US_CLINIC_API_URL = '/contact/sendMail/clinic';
    public static GET_ALL_CAREERS_API_URL = '/career/getActiveCareers';
    public static GET_ALL_UN_EXPIRED_API_URL = '/career/getUnexpiredCareers';
    public static GET_ALL_PARTNERS_API_URL = '/content/getPartners';
    public static GET_ALL_FAQS_API_URL = '/content/getFaq';
    public static GET_NEW_CONTENT_API_URL = '/content/getNewContent/';

    // Bank Accounts
    public static GET_BANK_ACCOUNTS = '/customer/bankAccount/all/';
    public static SAVE_BANK_ACCOUNT = '/customer/saveBankAccount';
    public static SAVE_BANK_ACCOUNT_OUTSIDE = '/customer/saveIntlBankAccount';
    public static REFUND_REQUEST = '/financial/payment_request';

    // Admin APIs
    public static GET_UPCOMING_RESERVATIONS_API_URL = '/admin/getUpcomingReservationsV1/';
    public static GET_PREVIOUS_RESERVATIONS_API_URL = '/admin/getPreviousReservations/';
    public static GET_RESERVATIONS_BY_IDS_API_URL = '/admin/getCustomerReservationsById/';
    public static UPDATE_RESERVATION_API_URL = '/admin/updateReservation/';
    public static GET_RESERVATION_DETAIL_BY_ID_API_URL = '/admin/getReservationDetailById/';
    public static SAVE_RATING_API_URL = '/admin/saveReviews/';
    public static saveCustomerReservation = '/admin/createReservation';
    public static deductAmountFromWallet = '/financial/customerPaidAmountFromWallet';

    public static getAllSpecialities = '/content/getAllSpecialities';
    public static getMedicalServiceBySpecialityId = '/content/getMedicalServiceBySpecialityId/';
    public static getAllCountries = '/core/get-countries';
    public static getCityByCountryId = '/content/getCityByCountryId/';
    public static getDrCityByCountryId = '/content/getDrCityByCountryId/';
    public static GET_DISTRICT_BY_ID = '/content/getDistrictByCityId/';
    public static getAllStatistics = '/content/getStatistics';
    public static getAllSettingsLinks = '/content/getAllLinks/settings';
    public static getAllContactInformation = '/contact/getContactInfo';
    public static getAllWebMenus = '/content/getWebMenus';
    public static getAllSpecialityOffers = '/content/getSpecialityOffers';
    public static getOffersBySpecialityId = '/content/getOfferBySpecialityId/';
    public static getOffersBySpecialityIdFILTERS = '/content/getOfferBySpecialityIdFilters/';
    public static getAllBranchOffers = '/content/getBranchOffers';
    public static getOffersByBranchId = '/content/getOfferByBranchId/';
    public static getOffersByBranchIdFILTERS = '/content/getOfferByBranchIdFilters/';
    public static getMostBookedOffers = '/content/getMostBookedOffers';
    public static getMostBookedOffersFilters = '/content/getMostBookedOffersFilters';
    public static getSpecialOffers = '/content/getSpecialOffers';
    public static getOfferDetail = '/content/getOfferDetail/';
    public static searchDoctorsByParams = '/content/searchDoctors/10?page=$page$';
    public static searchHospitalServicesByParams = '/content/searchHotelServices/10?page=$page$';
    public static searchSimilarDoctorsByParams = '/content/searchDoctors/4?page=$page$';
    public static searchDoctorFilters = '/content/doctorFilters';
    public static getOfferFilters = '/content/getofferFilters';
    public static getDoctorDetailsByBranch = '/content/getDoctorDetailsByBranch/$docId$/$brId$';
    public static getBranchDetailById = '/content/getBranchDetailById/';
    public static getBranchesByServiceProviderId = '/content/getBranches/all/6/$spId$?page=$page$';
    public static getDoctorsByBranchId = '/content/getDoctorsByBranchId/all/10/$brId$?page=$page$';
    public static getPaginatedOffersByBranchId = '/content/getOffersByBranchId/all/18/$brId$?page=$page$';
    public static getOfferSchedules = '/content/getOfferSchedule';
    public static getDoctorBranchSchedules = '/content/showDoctorScheduleByBranch';
    public static GET_DOCTOR_REVIEW_API_URL = '/content/getDoctorReviews/';
    public static GET_BRANCH_REVIEW_API_URL = '/content/getBranchReviews/';
    public static GET_BRANCH_FILTERS_API_URL = '/content/branchFilters?serviceProviderId=';
    public static GET_DOCTOR_FILTERS_API_URL = '/content/doctorFilters?serviceProviderId=';
    public static GET_OFFER_FILTERS_API_URL = '/content/offerFilters?serviceProviderId=';
    public static GET_MEDICTA_WAY_DATA_API_URL = '/content/getStaticTabsData/medicta_way';
    public static GET_ADD_CLINIC_DATA_API_URL = '/content/getStaticTabsData/add_clinic';
    public static GET_LAB_SERVICES_LISTING_API_URL = '/content/getLabServices';
    public static GET_CENTER_SCHEDULE_API_URL = '/content/displayCenterSchedule';

    // FCM Notifications APIs URL
    public static GET_ALL_UNREAD_FCM_NOTIFS_API_URL = '/user/notifications/all/';
    public static CLEAR_ALL_FCM_NOTIFS_API_URL = '/user/clearAllNotifications/';
    public static CLEAR_FCM_NOTIF_BY_ID_API_URL = '/user/updateNotification/';

    // Financial APIs URL
    public static GET_ALL_FINANCIAL_BALANCE_BY_CUSTOMER_ID_API_URL = '/financial/customerFinancialListing/10/$customer_id$?page=$page$';
    public static GET_ALL_FINANCIAL_TOTAL_BALANCE_BY_CUSTOMER_ID_API_URL = '/financial/customerFinancialAmountbyId/';

    // current localtion api
    public static GET_USER_CURRENT_LOCATION = '/customer/getLocation';

     // Push Notification
    public static CLEAR_ALL_NOTIFICATIONS = '/user/clearAllNotifications/';
    public static GET_ALL_NOTIFICATIONS = '/user/notifications/all/';
    public static GET_ALL_NOTIFICATIONS_COUNT = '/user/notifications/count/';
    public static GET_ALL_NOTIFICATIONS_SEE_ALL = '/user/notifications/listing/'

    public static GET_ALL_MS_BY_ID = '/content/medicalServiceById/'

}
