import {Component} from '@angular/core';
import {APIURLConstants} from '../util/api.url.constants';
import {ActivatedRoute, Router} from '@angular/router';
import {SpUtilService} from '../service/sp-util.service';
import {SharedService} from '../service/shared.service';
import {ToastrService} from 'ngx-toastr';
import {RequestsService} from '../service/requests.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Contact} from '../model/contact/contact';
import {environment} from '../../environments/environment';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'content-footer',
    templateUrl: '../templates/app.footer.template.html'
})
export class AppFooterComponent {

    mobileLinks: any [] = [];
    socialLinks: any[] = [];
    contactInfo: any[] = [];
    contactUsForm: FormGroup;
    isContactUsLoading: boolean = false;

    constructor(private router: Router,
                private activeRoute: ActivatedRoute,
                public spUtilService: SpUtilService,
                private translate: TranslateService,
                private sharedService: SharedService,
                private toastService: ToastrService,
                private formBuilder: FormBuilder,
                private requestsService: RequestsService) {
    }

    ngOnInit() {
        this.loadAllLinks();
        this.loadContactInformation();
        this.buildForm();
    }

    private buildForm() {
        this.contactUsForm = this.formBuilder.group({
            'name': [null, Validators.compose([Validators.required])],
            'email': [null, Validators.compose([Validators.pattern('[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}')])],
            'message': [null, Validators.compose([Validators.required])],
        });
    }

    loadAllLinks() {
        this.requestsService.getRequestUnAuth(APIURLConstants.getAllSettingsLinks)
            .subscribe(
                (response: Response) => {
                    if (response['responseCode'] === 'LINK_SUC_01') {
                        let allLinks = response['responseData'];
                        if (allLinks) {
                            this.mobileLinks = allLinks.mobile;
                            this.socialLinks = allLinks.social;
                        }
                    } else {
                        this.mobileLinks = [];
                        this.socialLinks = [];
                    }
                },
                (error: any) => {
                    this.translate.get('error').subscribe((data: any) => {
                        console.log(data['Internal Server Error. Please contact administrator.'], data['Medicta']);
                    });
                });
    }

    loadContactInformation() {
        this.requestsService.getRequestUnAuth(APIURLConstants.getAllContactInformation)
            .subscribe(
                (response: Response) => {
                    if (response['responseCode'] === 'CONTACT_INFO_SUC_01') {
                        this.contactInfo = response['responseData'];
                    } else {
                        this.contactInfo = [];
                    }
                },
                (error: any) => {
                    this.translate.get('error').subscribe((data: any) => {
                        console.log(data['Internal Server Error. Please contact administrator.'], data['Medicta']);
                    });
                });
    }

    onSignInClicked() {
        if (this.sharedService.isUserLoggedIn) {
            this.translate.get('error').subscribe((data: any) => {
                this.toastService.error(data['User already logged in.'], data['Error']);
            });
        } else {
            this.sharedService.isLoginClick.next(true);
        }
    }

    onRedirectCreateServiceProvider() {
        window.open(environment.service_provider_url, '_blank');
    }

    doContactUs(data: any) {
        this.isContactUsLoading = true;
        if (this.contactUsForm.invalid) {
            this.translate.get('error').subscribe((data: any) => {
                this.toastService.error(data['Required fields are missing.'], data['Medicta']);
            });
            this.isContactUsLoading = false;
            return;
        }
        let contactRequest: Contact = this.buildContactUsRequest(data);
        this.requestsService.postRequestUnAuth(
            APIURLConstants.CONTACT_US_API_URL,
            contactRequest)
            .subscribe(
                (response: Response) => {
                    if (response['responseCode'] === 'CONTACT_MSG_SUC_01') {
                        this.contactUsForm.reset();
                        this.toastService.success(response['responseMessage'], 'Contact Us');
                    } else {
                        this.toastService.error(this.spUtilService.selectedLanguage === 'en'?response['responseMessage']:response['name_ar'], 'Contact Us');
                    }
                    this.isContactUsLoading = false;
                },
                (error: any) => {
                    this.translate.get('error').subscribe((data: any) => {
                        this.toastService.error(data['Internal server error, please contact with administrator.'], data['Medicta']);
                    });
                    this.spUtilService.tokenExpired(error.status);
                    this.isContactUsLoading = false;
                });
    }

    private buildContactUsRequest(data: any): Contact {
        const contact: Contact = new Contact();
        contact.name = data.name;
        contact.email = data.email;
        contact.message = data.message;
        return contact;
    }

}
