import {Component, OnInit} from '@angular/core';
import {LoaderService} from '../service/loader.service';

@Component({
    selector: 'app-menu',
    templateUrl: '../templates/app.menu.template.html'
})
export class AppMenuComponent implements OnInit {


    constructor(public loaderService: LoaderService) {
    }

    ngOnInit() {

    }

}
