import {SpUtilService} from "../service/sp-util.service";
import {SharedService} from "../service/shared.service";
import {RequestsService} from "../service/requests.service";
import {ActivatedRoute, Router} from "@angular/router";
import {Component, OnInit} from '@angular/core';
import {APIURLConstants} from "../util/api.url.constants";

@Component({
    selector: 'content-component',
    templateUrl: '../templates/content.template.html',
})
export class ContentComponent implements OnInit {

    constructor(private router: Router,
                private activeRoute: ActivatedRoute,
                public spUtilService: SpUtilService,
                private sharedService: SharedService,
                private requestsService: RequestsService) {
    }

    ngOnInit() {

    }

    logout() {
        this.requestsService.postRequest(
            APIURLConstants.LOGOUT_API_URL,
            {})
            .subscribe(
                (response: Response) => {
                    if (response['responseCode'] === 'AUTH_SUC_03') {
                        this.spUtilService.logout();
                    }
                },
                (error: any) => {
                    this.spUtilService.tokenExpired(error.status);
                }
            );
    }

}
