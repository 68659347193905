export class CreateUser {
    email: string;
    password: string;
    password_confirmation: string;
    mobile_code: any;
    mobile_number: string;
    user_type: string = 'customer';
    is_active: number;
    constructor() {

    }

}
