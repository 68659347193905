import {Component, OnDestroy, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {ToastrService} from 'ngx-toastr';
import {MessagingService} from '../service/messaging.service';
import { RequestsService } from '../service/requests.service';
import {SharedService} from '../service/shared.service';
import {SpUtilService} from '../service/sp-util.service';
import { APIURLConstants } from '../util/api.url.constants';

@Component({
    selector: 'app-root',
    templateUrl: '../templates/app.template.html',
    styleUrls: ['./app.components.css']

})
export class AppComponent implements OnInit, OnDestroy {

    message;
    userCurrentLocation: any;
    userInfo: any;

    constructor(private translate: TranslateService,
                private messagingService: MessagingService,
                private sharedService: SharedService,
                private requestsService: RequestsService,
                public adminUtilService: SpUtilService,
                private toastrService: ToastrService) {
                this.userInfo = JSON.parse(localStorage.getItem(btoa('user-info')));

        translate.setDefaultLang(localStorage.getItem('selectedLanguage'));
    }

    ngOnInit() {
        this.messagingService.requestPermission();
        this.messagingService.receiveMessage();
        this.messagingService.currentMessage.subscribe((message) => {
            if (message) {
                this.toastrService.success(message.notification.body, message.notification.title);
                this.sharedService.newNotificationLoad.next(true);
            }
        });
        if (!localStorage.getItem('selectedLanguage')) {
            localStorage.setItem('selectedLanguage', 'en');
        }
        let language = localStorage.getItem('selectedLanguage');
        this.adminUtilService.changeLayoutOrientation(language, 'header');
        this.translate.use(language);
        this.adminUtilService.selectedLanguage = language;
        // if(this.userInfo){
            navigator.geolocation.getCurrentPosition((position) => {
                let latLong = JSON.parse(localStorage.getItem('latLong'));
                console.log('latlong',latLong);
                
                if(latLong && latLong.lat === position.coords.latitude && latLong.long === position.coords.longitude){

                }else{
                    this.getCurrentLocation(position)
                }
               
                
        })
        // }
    }
    getCurrentLocation(position){
        this.requestsService.getRequestUnAuth(APIURLConstants.GET_USER_CURRENT_LOCATION+"?longitude="+position.coords.longitude+"&latitude="+position.coords.latitude).subscribe(
            (response: Response) => {
                
                if (response['responseCode'] === 'SEARCH_SUC_01') {
                    
                    this.userCurrentLocation = response['responseData'];
                    
                    let latLong = {
                        lat: position.coords.latitude,
                        long: position.coords.longitude
                    }
                   // console.log('user current location',this.userCurrentLocation);
                    localStorage.setItem('latLong', JSON.stringify(latLong));
                    localStorage.setItem('currentLocation', JSON.stringify(response['responseData']));
                }
            },
            (error: any) => {
                console.log(error)
            });
    }
    ngOnDestroy() {
    }

    ngAfterViewInit() {

    }
}
