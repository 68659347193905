import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-secondary-services',
    templateUrl: '../../templates/secondary-services/app-second-page.html',
    styleUrls: ['../../../assets/css/secondary-services/app-second-page.css']
})
export class SecondPageComponent implements OnInit {

    constructor() { }

    ngOnInit() { }

}

