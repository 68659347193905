export class CountryCode {
    constructor() {
    }

    public static countryCode = [
        {name: "Afghanistan +93", value: "+93"},
        {
            name: "Antarctica +0",
            value: "+0"
        }, {
            name: "Australia +61",
            value: "+61"
        }, {name: "Austria +43", value: "+43"}, {
            name: "Azerbaijan +994",
            value: "+994"
        }, {name: "Bangladesh +880", value: "+880"}, {
            name: "Barbados +1246",
            value: "+1246"
        }, {name: "Belgium +32", value: "+32"}, {
            name: "Belize +501",
            value: "+501"
        },  {
            name: "Bhutan +975",
            value: "+975"
        },  {
            name: "Bosnia and Herzegovina +387",
            value: "+387"
        },
        {name: "Brazil +55", value: "+55"},
        {name: "Brunei +673", value: "+673"}, {
            name: "Bulgaria +359",
            value: "+359"
        },
        {name: "Cambodia +855", value: "+855"}, {
            name: "Canada +1",
            value: "+1"
        },
        {name: "Chile +56", value: "+56"}, {
            name: "China +86",
            value: "+86"
        }, {name: "Colombia +57", value: "+57"},
        {name: "Republic Of The Congo +242", value: "+242"}, {
            name: "Democratic Republic Of The Congo +242",
            value: "+242"
        },
        {name: "Cuba +53", value: "+53"},
        {name: "Czech Republic +420", value: "+420"}, {
            name: "Denmark +45",
            value: "+45"
        }, {
            name: "Egypt +20",
            value: "+20"
        }, {name: "Fiji Islands +679", value: "+679"}, {
            name: "Finland +358",
            value: "+358"
        }, {name: "France +33", value: "+33"},
        {name: "French Polynesia +689", value: "+689"},
        {name: "Gabon +241", value: "+241"},
        {name: "Georgia +995", value: "+995"},
        {name: "Germany +49", value: "+49"},
         {
            name: "Greece +30",
            value: "+30"
        }, {name: "Greenland +299", value: "+299"},
         {name: "Guinea +224", value: "+224"},
        {name: "Guyana +592", value: "+592"},
        {name: "Hong Kong S.A.R. +852", value: "+852"},
        {
            name: "Hungary +36",
            value: "+36"
        }, {name: "Iceland +354", value: "+354"},
        {name: "India +91", value: "+91"}, {
            name: "Indonesia +62",
            value: "+62"
        }, {name: "Iran +98", value: "+98"}, {name: "Iraq +964", value: "+964"}, {
            name: "Ireland +353",
            value: "+353"
        }, {name: "Israel +972", value: "+972"}, {name: "Italy +39", value: "+39"},
        {name: "Japan +81", value: "+81"}, {name: "Jersey +44", value: "+44"}, {
            name: "Jordan +962",
            value: "+962"
        }, {name: "Kazakhstan +7", value: "+7"}, {name: "Kenya +254", value: "+254"}, {
            name: "Kiribati +686",
            value: "+686"
        }, {name: "Korea North +850", value: "+850"}, {
            name: "Korea South +82",
            value: "+82"
        }, {name: "Kuwait +965", value: "+965"}, {name: "Kyrgyzstan +996", value: "+996"},
        {name: "Lebanon +961", value: "+961"}, {
            name: "Lesotho +266",
            value: "+266"
        }, {name: "Liberia +231", value: "+231"}, {
            name: "Libya +218",
            value: "+218"
        }, {
            name: "Lithuania +370",
            value: "+370"
        }, {name: "Macedonia +389", value: "+389"},
        {name: "Malawi +265", value: "+265"}, {name: "Malaysia +60", value: "+60"}, {
            name: "Maldives +960",
            value: "+960"
        }, {name: "Mali +223", value: "+223"}, {
            name: "Malta +356",
            value: "+356"
        }, {
            name: "Marshall Islands +692",
            value: "+692"
        },
        {name: "Mauritius +230", value: "+230"},
        {name: "Mexico +52", value: "+52"}, {
            name: "Micronesia +691",
            value: "+691"
        }, {name: "Moldova +373", value: "+373"}, {
            name: "Monaco +377",
            value: "+377"
        }, {name: "Mongolia +976", value: "+976"}, {
            name: "Montserrat +1664",
            value: "+1664"
        }, {name: "Morocco +212", value: "+212"},
        {name: "Myanmar +95", value: "+95"},
        {name: "Namibia +264", value: "+264"}, {
            name: "Nauru +674",
            value: "+674"
        }, {name: "Nepal +977", value: "+977"}, {
            name: "Netherlands Antilles +599",
            value: "+599"
        }, {name: "Netherlands The +31", value: "+31"}, {
            name: "New Caledonia +687",
            value: "+687"
        }, {name: "New Zealand +64", value: "+64"},
        {name: "Nigeria +234", value: "+234"}, {
            name: "Niue +683",
            value: "+683"
        },  {
            name: "Northern Mariana Islands +1670",
            value: "+1670"
        }, {name: "Norway +47", value: "+47"}, {name: "Oman +968", value: "+968"}, {
            name: "Pakistan +92",
            value: "+92"
        }, {name: "Palau +680", value: "+680"}, {
            name: "Palestinian Territory Occupied +970",
            value: "+970"
        }, {name: "Panama +507", value: "+507"},
        {name: "Paraguay +595", value: "+595"}, {name: "Peru +51", value: "+51"}, {
            name: "Philippines +63",
            value: "+63"
        }, {name: "Pitcairn Island +0", value: "+0"}, {
            name: "Poland +48",
            value: "+48"
        }, {name: "Portugal +351", value: "+351"},
        {name: "Qatar +974", value: "+974"}, {name: "Reunion +262", value: "+262"}, {
            name: "Romania +40",
            value: "+40"
        }, {name: "Russia +70", value: "+70"}, {
            name: "Rwanda +250",
            value: "+250"
        }, {name: "Saint Lucia +1758", value: "+1758"}, {
            name: "Saint Pierre and Miquelon +508",
            value: "+508"
        }, {name: "Saudi Arabia +966", value: "+966"}, {
            name: "Senegal +221",
            value: "+221"
        }, {name: "Serbia +381", value: "+381"}, {
            name: "Seychelles +248",
            value: "+248"
        }, {name: "Sierra Leone +232", value: "+232"}, {
            name: "Singapore +65",
            value: "+65"
        },
        {
            name: "South Africa +27",
            value: "+27"
        }, {name: "South Georgia +0", value: "+0"}, {
            name: "South Sudan +211",
            value: "+211"
        }, {name: "Spain +34", value: "+34"}, {name: "Sri Lanka +94", value: "+94"}, {
            name: "Sudan +249",
            value: "+249"
        }, {name: "Suriname +597", value: "+597"}, {
            name: "Svalbard And Jan Mayen Islands +47",
            value: "+47"
        }, {name: "Swaziland +268", value: "+268"}, {
            name: "Sweden +46",
            value: "+46"
        }, {name: "Switzerland +41", value: "+41"}, {name: "Syria +963", value: "+963"}, {
            name: "Taiwan +886",
            value: "+886"
        }, {name: "Tajikistan +992", value: "+992"}, {
            name: "Tanzania +255",
            value: "+255"
        }, {name: "Thailand +66", value: "+66"}, {name: "Togo +228", value: "+228"},
        {name: "Tonga +676", value: "+676"},
        {name: "Tunisia +216", value: "+216"}, {
            name: "Turkey +90",
            value: "+90"
        }, {name: "Turkmenistan +7370", value: "+7370"}, {
            name: "Turks And Caicos Islands +1649",
            value: "+1649"
        }, {name: "Tuvalu +688", value: "+688"}, {name: "Uganda +256", value: "+256"}, {
            name: "Ukraine +380",
            value: "+380"
        }, {name: "United Arab Emirates +971", value: "+971"}, {
            name: "United Kingdom +44",
            value: "+44"
        }, {name: "United States +1", value: "+1"}, {
            name: "United States Minor Outlying Islands +1",
            value: "+1"
        }, {name: "Uruguay +598", value: "+598"}, {
            name: "Uzbekistan +998",
            value: "+998"
        }, {name: "Vanuatu +678", value: "+678"}, {
            name: "Vatican City State (Holy See) +39",
            value: "+39"
        }, {
            name: "Vietnam +84",
            value: "+84"
        }, {name: "Yemen +967", value: "+967"}, {
            name: "Zambia +260",
            value: "+260"
        }, {name: "Zimbabwe +263", value: "+263"}
    ];

    public static countryCode1 = [
        {name: '+966', value: '+966'},
        {name: '+92', value: '+92'},
    ];
}
