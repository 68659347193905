import {RouterModule, Routes} from '@angular/router';
import {ModuleWithProviders} from '@angular/core';
import {ContentComponent} from './components/content.component';
import {FilterContentComponent} from './components/filter-content.component';
import {SecondaryServicesComponent} from "./components/secondary-services/secondary-services.component";
import {SecondPageComponent} from "./components/second-page/second-page.component";

export const routes: Routes = [
    {
        path: '', component: ContentComponent,
        children: [
            {path: '', loadChildren: './components/home/home.module#HomeModule'},
        ]
    },
    {
        path: 'customer', component: FilterContentComponent,
        children: [
            {path: 'profile', loadChildren: './components/customer-profile/customer-profile.module#CustomerProfileModule'},
        ]
    },
     {
         path: 'all-doctors', component: FilterContentComponent,
         children: [
             {path: '', loadChildren: './components/all-doctors/all-doctors.module#AllDoctorsModule'},
         ]
     },
    {
        path: 'doctors', component: FilterContentComponent,
        children: [
            {path: '', loadChildren: './components/doctors/doctors.module#DoctorsModule'},
            {path: ':id', loadChildren: './components/doctor-booking/doctor-booking.module#DoctorBookingModule'},
        ]
    },
    {
        path: 'branches', component: FilterContentComponent,
        children: [
            {path: '', loadChildren: './components/branches/branches.module#BranchesModule'},
        ]
    },
    {
        path: 'call-us', component: FilterContentComponent,
        children: [
            {path: '', loadChildren: './components/contact-us/contact-us.module#ContactUsModule'},
        ]
    },
    {
        path: 'specialist', component: ContentComponent,
        children: [
            {path: '', loadChildren: './components/specialist/specialist.module#SpecialistModule'},
        ]
    },
    {
        path: 'cart', component: FilterContentComponent,
        children: [
            {path: '', loadChildren: './components/cart/cart.module#CartModule'},
        ]
    },
    {
        path: 'hospital-service', component: FilterContentComponent,
        children: [
            {path: '', loadChildren: './components/hospital-service/hospital-service.module#HospitalServiceModule'},
            {path: 'detail', loadChildren: './components/hospital/hospital.module#HospitalModule'},
        ]
    },
    {
        path: '', component: FilterContentComponent,
        children: [
            {path: 'about', loadChildren: './components/content-pages/content-pages.module#ContentPagesModule'},
            {path: 'new-content', loadChildren: './components/content-pages/content-pages.module#ContentPagesModule'},
            {path: 'term', loadChildren: './components/content-pages/content-pages.module#ContentPagesModule'},
            {path: 'privacy-policy', loadChildren: './components/content-pages/content-pages.module#ContentPagesModule'},
            {path: 'reservation-policy', loadChildren: './components/content-pages/content-pages.module#ContentPagesModule'},
            {path: 'payment-method', loadChildren: './components/content-pages/content-pages.module#ContentPagesModule'},
        ]
    },
    {
        path: 'partners', component: FilterContentComponent,
        children: [
            {path: '', loadChildren: './components/partner/partner.module#PartnerModule'},
        ]
    },
    {
        path: 'careers', component: FilterContentComponent,
        children: [
            {path: '', loadChildren: './components/career/career.module#CareerModule'},
        ]
    },
    {
        path: 'faq', component: FilterContentComponent,
        children: [
            {path: '', loadChildren: './components/faq/faq.module#FaqModule'},
        ]
    },
    {
        path: 'more', loadChildren: './components/more/more.module#MoreModule',
    },
    {
        path: 'add-clinic', loadChildren: './components/add-clinic/add-clinic.module#AddClinicModule',
    },
    {
        path: 'offers', component: FilterContentComponent,
        children: [
            {path: '', loadChildren: './components/offers/offers.module#OffersModule'},
        ]
    },
    {
        path: 'secondary-services', component: SecondaryServicesComponent,
    },
    {
        path: 'second-page', component: SecondPageComponent,
    }
];

export const AppRoutingModule: ModuleWithProviders =
    RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'});
