import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-secondary-services',
    templateUrl: '../../templates/secondary-services/app-secondary-services.html',
    styleUrls: ['../../../assets/css/secondary-services/app-secondary-services.css']
})
export class SecondaryServicesComponent implements OnInit {

    constructor() { }

    ngOnInit() { }

}

