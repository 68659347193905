import {Injectable, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {SharedService} from './shared.service';

@Injectable()
export class SpUtilService implements OnInit {

    userInfo: any = {};
    selectedLanguageName: string = 'English';
    selectedLanguage: string = 'en';

    constructor(private router: Router,
                private sharedService: SharedService) {
    };

    ngOnInit() {

    }

    tokenExpired(code: number) {
        if (code === 401 || code === 403) {
            localStorage.removeItem(btoa('access_token'));
            localStorage.removeItem(btoa('user-info'));
            this.router.navigate(['/']);
        }
    }

    public logout() {
        localStorage.removeItem(btoa('access_token'));
        localStorage.removeItem(btoa('user-info'));
        localStorage.removeItem(btoa('CartItems'));
        this.sharedService.isUserLoggedIn = false;

        this.router.navigate(['/']);
    }

    getUserLang() {
        return localStorage.getItem(btoa('user_lang')) != undefined ? localStorage.getItem(btoa('user_lang')) : 'en';
    }

    changeLayoutOrientation(lang: string, componentType: string): boolean {
        let isSuccess = false;
        const navBar: HTMLLinkElement = <HTMLLinkElement> document.getElementById('navbar-css');
        const customStyle: HTMLLinkElement = <HTMLLinkElement> document.getElementById('custom-css');
        const responsive: HTMLLinkElement = <HTMLLinkElement> document.getElementById('responsive-css');
        const megaMenu: HTMLLinkElement = <HTMLLinkElement> document.getElementById('megaMenu-css');
        const styleRTL: HTMLLinkElement = <HTMLLinkElement> document.getElementById('style-rtl');

        const mainHtml = document.getElementsByTagName('html');

        if (lang === 'en') {
            mainHtml[0].removeAttribute('dir');
            mainHtml[0].removeAttribute('lang');
            if (componentType === 'header') {
                styleRTL.href = '#';
            }
            this.selectedLanguage = 'en';
            this.selectedLanguageName = 'English';
            isSuccess = true;
        } else {
            let langAtt;
            // let dirAtt;
            // dirAtt = document.createAttribute('dir');
            // dirAtt.value = 'rtl';
            langAtt = document.createAttribute('lang');
            langAtt.value = 'ar';
            // mainHtml[0].setAttributeNode(dirAtt);
            // mainHtml[0].setAttributeNode(langAtt);
            if (componentType === 'header') {
                styleRTL.href = 'assets/css/style-rtl.css';
            }
            this.selectedLanguage = 'ar';
            this.selectedLanguageName = 'العربية';
            isSuccess = false;
        }
        return isSuccess;
    }
}
