import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AsyncPipe, LocationStrategy, PathLocationStrategy} from '@angular/common';
import {AppRoutingModule} from './app.routing.module';
import {AppComponent} from './components/app.component';
import {RequestsService} from './service/requests.service';
import {SpUtilService} from './service/sp-util.service';
import {PrimengModule} from './shared/primeng/primeng.module';
import {ContentComponent} from './components/content.component';
import {FilterContentComponent} from './components/filter-content.component';
import {SharedService} from './service/shared.service';
import {LoaderService} from './service/loader.service';
import {DateTimeService} from './service/date.time.service';
import {AppHeaderComponent} from './components/app.header.component';
import {AppFilterHeaderComponent} from './components/app.filter-header.component';
import {AppFooterComponent} from './components/app.footer.component';
import {AppMenuComponent} from './components/app.menu.component';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {InterceptorService} from './service/interceptor.service';
import {ResourceNotFoundComponent} from './components/error/resource.not.found.component';
import {DialogModule} from 'primeng';
import {AngularFireDatabaseModule} from '@angular/fire/database';
import {ToastrModule} from 'ngx-toastr';
import {SanitizeHtmlPipe} from './pipe/SanitizeHtml';
import {NgxUiLoaderConfig, NgxUiLoaderHttpModule, NgxUiLoaderModule, POSITION, SPINNER} from 'ngx-ui-loader';
import {AngularFireAuthModule} from '@angular/fire/auth';
import {AngularFireMessagingModule} from '@angular/fire/messaging';
import {AngularFireModule} from '@angular/fire';
import {environment} from '../environments/environment';
import {MessagingService} from './service/messaging.service';
import {SecondaryServicesComponent} from "./components/secondary-services/secondary-services.component";
import {SecondPageComponent} from "./components/second-page/second-page.component";

const ngxUiLoaderConfig: NgxUiLoaderConfig = {
    bgsColor: '#0C8944',
    bgsPosition: POSITION.topLeft,
    bgsSize: 60,
    bgsType: SPINNER.ballScaleMultiple, // background spinner type
    fgsType: SPINNER.ballScaleMultiple, // foreground spinner type
};

@NgModule({
    imports: [
        BrowserModule,
        FormsModule,
        AppRoutingModule,
        HttpClientModule,
        BrowserAnimationsModule,
        PrimengModule,
        DialogModule,
        ReactiveFormsModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            },
            extend: true,
            defaultLanguage: 'en'
        }),
        AngularFireDatabaseModule,
        ToastrModule.forRoot({
            timeOut: 10000,
            closeButton: true,
        }),
        NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
        NgxUiLoaderHttpModule,
        AngularFireDatabaseModule,
        AngularFireAuthModule,
        AngularFireMessagingModule,
        AngularFireModule.initializeApp(environment.firebase),
    ],
    declarations: [
        AppComponent,
        AppHeaderComponent,
        AppFilterHeaderComponent,
        AppFooterComponent,
        ContentComponent,
        FilterContentComponent,
        AppMenuComponent,
        ResourceNotFoundComponent,
        SanitizeHtmlPipe,
        SecondaryServicesComponent,
        SecondPageComponent
    ],
    providers:
        [
            {provide: LocationStrategy, useClass: PathLocationStrategy},
            {provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi: true},
            RequestsService,
            SpUtilService,
            SharedService,
            LoaderService,
            DateTimeService,
            AsyncPipe,
            SanitizeHtmlPipe,
            MessagingService
        ],
    bootstrap:
        [AppComponent]
})

export class AppModule {
}

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
