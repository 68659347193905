    import {SpUtilService} from '../service/sp-util.service';
import {SharedService} from '../service/shared.service';
import {RequestsService} from '../service/requests.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Component, OnInit} from '@angular/core';
import {APIURLConstants} from '../util/api.url.constants';

@Component({
    selector: 'content-filter-component',
    templateUrl: '../templates/filter-content.template.html',
})
export class FilterContentComponent implements OnInit {

    isCartPaymentPage : boolean = false;

    constructor(private router: Router,
                private activeRoute: ActivatedRoute,
                public spUtilService: SpUtilService,
                private sharedService: SharedService,
                private requestsService: RequestsService) {
    }

    ngOnInit() {

    }

    showHideElementsOnCart() {
        // switch (this.router.url) {
        //     case "/cart/payment" :
        //         this.isCartPaymentPage = !this.isCartPaymentPage;
        //     // case "/cart" :
        //     //     this.isCartPaymentPage = !this.isCartPaymentPage;
        // }
        return this.isCartPaymentPage;
    }

    logout() {
        this.requestsService.postRequest(
            APIURLConstants.LOGOUT_API_URL,
            {})
            .subscribe(
                (response: Response) => {
                    if (response['responseCode'] === 'AUTH_SUC_03') {
                        this.spUtilService.logout();
                    }
                },
                (error: any) => {
                    this.spUtilService.tokenExpired(error.status);
                }
            );
    }

    disableMenuBar(componentReference) {
        this.sharedService.emitter.next(componentReference.showMenuBar);
    }
}
