import {Injectable} from '@angular/core';

@Injectable()
export class LoaderService {

    inProgress: boolean = false;

    constructor() {
    }
}
