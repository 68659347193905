export class Contact {
    name: string;
    mobile_code: string;
    mobile_number: string;
    message: string;
    email: string;
    country_id: string;
    trade_name: string;
    his_job: string;

    constructor() {

    }

}
